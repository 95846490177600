import { Sun, Users, MapPin, Sparkles, Mail, Phone, Globe } from 'lucide-react';
import { Button } from '../components/ui/Button';

export default function AboutPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Bienvenue sur SUNDEALS</h1>
        <p className="text-xl text-gray-600 dark:text-gray-300">
          La communauté des bons plans intelligents
        </p>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
        <div className="flex items-center space-x-4 mb-6">
          <Sun className="w-8 h-8 text-yellow-500" />
          <h2 className="text-2xl font-semibold">Notre Mission</h2>
        </div>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          SUNDEALS est né d'une idée simple : créer un espace où les passionnés de bons plans peuvent se retrouver pour partager et découvrir les meilleures offres. Notre plateforme met en relation les dealmakers avertis avec ceux qui cherchent à faire des économies intelligentes.
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-6 mb-12">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <Users className="w-8 h-8 text-blue-500 mb-4" />
          <h3 className="text-xl font-semibold mb-3">Communauté Active</h3>
          <p className="text-gray-600 dark:text-gray-300">
            Rejoignez une communauté passionnée qui partage et commente les meilleurs deals chaque jour.
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <MapPin className="w-8 h-8 text-green-500 mb-4" />
          <h3 className="text-xl font-semibold mb-3">Bons Plans Locaux</h3>
          <p className="text-gray-600 dark:text-gray-300">
            Découvrez les offres près de chez vous grâce à notre fonction de géolocalisation.
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <Sparkles className="w-8 h-8 text-purple-500 mb-4" />
          <h3 className="text-xl font-semibold mb-3">Version BETA</h3>
          <p className="text-gray-600 dark:text-gray-300">
            Nous sommes en version bêta et travaillons chaque jour pour améliorer votre expérience.
          </p>
        </div>
      </div>

      <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6 mb-12">
        <h2 className="text-2xl font-semibold mb-4">Comment ça marche ?</h2>
        <div className="space-y-4">
          <div className="flex items-start space-x-4">
            <div className="w-6 h-6 rounded-full bg-blue-500 text-white flex items-center justify-center flex-shrink-0">1</div>
            <div>
              <h3 className="font-semibold">Inscrivez-vous</h3>
              <p className="text-gray-600 dark:text-gray-300">Créez votre compte gratuitement pour rejoindre la communauté.</p>
            </div>
          </div>
          
          <div className="flex items-start space-x-4">
            <div className="w-6 h-6 rounded-full bg-blue-500 text-white flex items-center justify-center flex-shrink-0">2</div>
            <div>
              <h3 className="font-semibold">Découvrez</h3>
              <p className="text-gray-600 dark:text-gray-300">Parcourez les bons plans partagés par la communauté.</p>
            </div>
          </div>
          
          <div className="flex items-start space-x-4">
            <div className="w-6 h-6 rounded-full bg-blue-500 text-white flex items-center justify-center flex-shrink-0">3</div>
            <div>
              <h3 className="font-semibold">Partagez</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Contribuez en partageant vos propres découvertes. Pour chaque bon plan publié, gagnez 100 SUNCOINS ! Ces SUNCOINS vous permettent d'obtenir des remises importantes chez nos partenaires sur la webapp SUNKDO.ONLINE.
              </p>
            </div>
          </div>
          
          <div className="flex items-start space-x-4">
            <div className="w-6 h-6 rounded-full bg-blue-500 text-white flex items-center justify-center flex-shrink-0">4</div>
            <div>
              <h3 className="font-semibold">Interagissez</h3>
              <p className="text-gray-600 dark:text-gray-300">Commentez, votez et discutez avec d'autres membres.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Contact section */}
      <div className="bg-gray-900 dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-12 text-white">
        <h2 className="text-3xl font-bold mb-8 text-center">Contactez-nous</h2>
        
        <div className="grid md:grid-cols-2 gap-12">
          {/* Contact Info */}
          <div className="space-y-8">
            <div className="flex items-center space-x-6">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Mail className="w-8 h-8 text-blue-500" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-1">Email</h3>
                <a href="mailto:contact@sundeals.io" className="text-blue-400 hover:text-blue-300 text-lg">
                  contact@sundeals.io
                </a>
              </div>
            </div>

            <div className="flex items-center space-x-6">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Phone className="w-8 h-8 text-blue-500" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-1">Téléphone</h3>
                <a href="tel:+33612345678" className="text-blue-400 hover:text-blue-300 text-lg">
                  +33 6 12 34 56 78
                </a>
              </div>
            </div>

            <div className="flex items-center space-x-6">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Globe className="w-8 h-8 text-blue-500" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Réseaux sociaux</h3>
                <div className="flex space-x-6">
                  <a href="https://twitter.com/sundeals" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    Twitter
                  </a>
                  <a href="https://facebook.com/sundeals" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    Facebook
                  </a>
                  <a href="https://instagram.com/sundeals" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-white/5 p-6 rounded-lg backdrop-blur-sm">
            <h3 className="text-xl font-semibold mb-6">Envoyez-nous un message</h3>
            <form className="space-y-6">
              <div>
                <label className="block text-sm font-medium mb-2">Nom</label>
                <input
                  type="text"
                  className="w-full px-4 py-3 rounded-lg bg-white/10 border border-white/20 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-white placeholder-gray-400"
                  required
                  placeholder="Votre nom"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Email</label>
                <input
                  type="email"
                  className="w-full px-4 py-3 rounded-lg bg-white/10 border border-white/20 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-white placeholder-gray-400"
                  required
                  placeholder="votre@email.com"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Message</label>
                <textarea
                  rows={4}
                  className="w-full px-4 py-3 rounded-lg bg-white/10 border border-white/20 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-white placeholder-gray-400"
                  required
                  placeholder="Votre message..."
                />
              </div>
              <Button 
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-lg transition-colors"
              >
                Envoyer
              </Button>
            </form>
          </div>
        </div>
      </div>

      {/* Beta version notice */}
      <div className="mt-12 text-center">
        <div className="inline-block bg-yellow-100 dark:bg-yellow-900/20 rounded-lg p-4">
          <h3 className="text-lg font-semibold mb-2">Version BETA</h3>
          <p className="text-gray-600 dark:text-gray-300">
            SUNDEALS est actuellement en version bêta. Nous travaillons constamment à l'amélioration de la plateforme et apprécions vos retours pour nous aider à créer la meilleure expérience possible.
          </p>
        </div>
      </div>
    </div>
  );
}